.licenseplate {
  background-image: url("../../common/images/eu-licence-plate.jpg");

  font-size: 18px;
  color: #333;
  background-color: #fff;
  background-repeat: no-repeat;
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: bolder !important;
  text-transform: uppercase;
  text-align: center;
  width: 130px;
  border-radius: 5px;
  outline: none;
  background-size: 12px 21px;
  border: 1px solid;
}
