@page {
  size: A4;
  margin: 1.9cm;
  -webkit-print-color-adjust: exact;
}

@media print {
  body {
    position: static !important;
  }
  body * {
    display: block !important;
    contain: unset !important;
    overflow: visible !important;
  }
  ion-header {
    display: none !important;
  }
  .day-container {
    break-after: always !important;
    page-break-after: always !important;
    page-break-before: always !important;
    position: relative;
    overflow: hidden;
  }
  .day-container:first-child {
    page-break-before: unset !important;
  }

  .ion-page {
    display: block !important;
    contain: unset !important;
    overflow: visible !important;
  }
}
