.modal-slot .modal-wrapper .ion-page {
  padding: 1em;

  header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;

    @media(max-width: 800px) {

      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      ion-buttons {
        margin-bottom: 16px;
        justify-content: center;
        ion-button {
          min-width: 48%;
        }
      }
    }

    
    ion-buttons{ display: flex; flex-direction: row; flex-wrap: wrap;}

    ion-button {
      font-weight: bolder;
      --border-width: 1px;
      margin-bottom: 8px;
    }


    .info-slot {
      display: inline-block;

      h1 {
        margin-top: 0;
        width: 100%;
      }

      time {
        width: 100%;

      }
    }
  }

  section.slot-content {

    section {
      &.chantier {
        ion-chip.chantier-nom {
          font-weight: bolder;
          cursor: default;
          color: white;
          
        text-shadow: 0 0 4px rgba(0,0,0,1);

        }
      }

      h2 {
        font-size: 1em;
        padding-top: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-top: 1px solid grey;
      }

      article {
        h3 {
          font-size: 1em;
          font-weight: bolder;
          display: inline;
          margin-right: 15px;
        }

        ion-chip.not-clickable {
          cursor: default;
        }

      }

    }
  }
}