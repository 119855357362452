.filter-container {
  background-color: #8080800a;

  p.filter-by {
    margin: 5px 0 0 16px;
    color: gray;
    text-transform: uppercase;
    font-size: 13px;
  }

  .filter-values-display {
    padding: 5px 15px 0px;

    ion-select {
      background-color: #f0f0f0;

      &.driver-select,
      &.vehicle-select {
        margin-left: 25px !important;
      }
    }

    ion-chip.filter-chip {
      height: 22px;
      background-color: white;

      ion-icon {
        font-size: 12px;
      }
    }
  }

  .filter-select {
    min-width: 180px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
